@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'EB Garamond';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/EBGaramond-Regular.ttf) format('truetype');
  }
  @font-face {
    font-family: 'EB Garamond';
    font-style: bold;
    font-weight: 800;
    font-display: swap;
    src: url(/fonts/EBGaramond-Bold.ttf) format('truetype');
  }
  @font-face {
    font-family: 'EB Garamond';
    font-weight: 600;
    font-display: swap;
    src: url(/fonts/EBGaramond-SemiBold.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Satoshi';
    src: url('/fonts/Satoshi-Variable.woff2') format('woff2');
    font-weight: 300 900;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Satoshi';
    src: url('/fonts/Satoshi-VariableItalic.woff2') format('woff2');
    font-weight: 300 900;
    font-display: swap;
    font-style: italic;
  }


  body {
    @apply font-sans text-base;
  }

  .preview-handwriting {
    bottom: 5%;
    left: 86%;
  }

  .sb-preview-wrapper {
    @apply font-sb-font-family text-sb-text bg-sb-background;
  }

  .sb-preview-item {
    @apply hover:bg-gray-800/10 flex flex-col gap-4 py-2 px-4 select-none;
  }

  .sb-preview-image {
    @apply bg-center bg-no-repeat bg-cover;
    width: 168px;
    height: 121px;
  }

  .beehiiv .sb-preview-image,
  .ghost .sb-preview-image {
    @apply rounded-md;
    width: 200px;
    height: auto;
  }

  .podcast .sb-preview-image {
    @apply rounded-md;
    width: 150px;
    height: 150px;
  }

  @media (min-width: 450px) {
    .sb-preview-item {
      @apply flex-row;
    }
    .substack .sb-preview-image {
      @apply self-center;
    }
  }

  .sb-preview-content-wrap {
    @apply flex-1 flex flex-col justify-between;
  }
  .sb-preview-content {
    @apply text-preview-sm;
  }

  .sb-preview-item-title {
    @apply font-bold;
  }
  .sb-preview-item-description {
    @apply mt-2 font-light text-preview-sm opacity-80;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .sb-preview-author-date-wrap {
    @apply pb-2 mt-4 text-preview-xs;
  }
  .beehiiv .sb-preview-author-date-wrap,
  .ghost .sb-preview-author-date-wrap {
    @apply pb-0 flex font-light;
  }

  .sb-preview-last-row {
    @apply flex space-x-2 items-center pt-2 opacity-80;
  }
  .sb-preview-date,
  .sb-preview-author {
    @apply font-light;
  }

  .truncate-overflow {
    --max-lines: 2;
    position: relative;
    max-height: calc(20px * var(--max-lines));
    overflow: hidden;
    padding-right: 1rem; /* space for ellipsis */
  }
  .truncate-overflow::before {
    position: absolute;
    content: '...';
    inset-block-end: 0; /* "bottom" */
    inset-inline-end: 0; /* "right" */
  }
  .truncate-overflow::after {
    content: '';
    position: absolute;
    inset-inline-end: 0; /* "right" */
    width: 1rem;
    height: 1rem;
    background: var(--sb-background, white);
  }

  .notion {
    @apply font-serif text-base !important;
  }

  .notion .notion-page-icon-wrapper,
  .notion .notion-header,
  .notion .notion-collection-header,
  .notion .notion-viewport {
    @apply hidden !important;
  }

  .notion .notion-page-icon-hero {
    @apply hidden !important;
  }

  .notion .notion-page-icon-inline {
    @apply hidden !important;
  }

  .notion .notion-page-title .notion-page-title-text {
    @apply text-base font-bold whitespace-normal !important;
  }
  
  :root {
    --background: 0 0% 100%;
    --foreground: 20 14.3% 4.1%;
    --card: 0 0% 100%;
    --card-foreground: 20 14.3% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 20 14.3% 4.1%;
    --primary: 24.6 95% 53.1%;
    --primary-foreground: 60 9.1% 97.8%;
    --secondary: 60 4.8% 95.9%;
    --secondary-foreground: 24 9.8% 10%;
    --muted: 60 4.8% 95.9%;
    --muted-foreground: 25 5.3% 44.7%;
    --accent: 60 4.8% 95.9%;
    --accent-foreground: 24 9.8% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 20 5.9% 90%;
    --input: 20 5.9% 90%;
    --ring: 24.6 95% 53.1%;
    --radius: 0.3rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 20 14.3% 4.1%;
    --foreground: 60 9.1% 97.8%;
    --card: 20 14.3% 4.1%;
    --card-foreground: 60 9.1% 97.8%;
    --popover: 20 14.3% 4.1%;
    --popover-foreground: 60 9.1% 97.8%;
    --primary: 20.5 90.2% 48.2%;
    --primary-foreground: 60 9.1% 97.8%;
    --secondary: 12 6.5% 15.1%;
    --secondary-foreground: 60 9.1% 97.8%;
    --muted: 12 6.5% 15.1%;
    --muted-foreground: 24 5.4% 63.9%;
    --accent: 12 6.5% 15.1%;
    --accent-foreground: 60 9.1% 97.8%;
    --destructive: 0 72.2% 50.6%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 12 6.5% 15.1%;
    --input: 12 6.5% 15.1%;
    --ring: 20.5 90.2% 48.2%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer utilities {
  .animate-delay-2000 {
    animation-delay: 2s;
  }
  .animate-delay-4000 {
    animation-delay: 4s;
  }
}

.kg-callout-card {
  @apply flex gap-4 px-4 py-3 rounded-lg;
}

.kg-callout-card-yellow {
  @apply bg-yellow-100 text-yellow-900;
}

.kg-callout-card a {
  @apply text-yellow-900;
}

figure iframe {
  @apply w-full aspect-video h-auto rounded-lg;
}

.kg-toggle-card-icon {
  display: none;
}

.kg-image-card a {
  @apply flex items-center justify-center;
}

.ghost-post img {
  @apply rounded-lg shadow-img-shadow;
}

.ghost-post figure figcaption {
  @apply text-sm text-gray-500 text-center mt-2;
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
